import React, { useState} from 'react'
import wallet from '../../Assets/empty-wallet.png'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext'
import { useUser } from '../../Context/UserContext'
import success from '../../Assets/success.png'
import { useOutletContext } from 'react-router-dom'

export default function ArtistFinancials() {

  const [ signUpInProgress, setSignupInProgress] = useState(false)

  const { currentUser } = useAuth()
  const { getUserInfo, userInfo } = useUser()
  const { navOpen } = useOutletContext();

  const createConnectAccount = () => {
    setSignupInProgress(true)
    axios.post(`${process.env.REACT_APP_API_URL}/payments/create-connect-account`, {
        userId: currentUser.uid,
        email: currentUser.email,
    })
    .then((res) => {
        console.log(res.data)
        window.open(res.data.url);
        setSignupInProgress(false)
        getUserInfo()

    })
    .catch((error) => {
        toast.error('A problem occurred with the payment system')
        setSignupInProgress(false)
        console.log(error)
    })
}

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      <div className='teacher-dash-content' style={{padding:'2rem'}}>
      {userInfo?.connectData?.paymentsPermitted

        ?
        <div style={{width: '100%',minHeight: '100vh', display: 'flex', justifyContent: 'baseline', alignItems:'center', flexDirection:'column', paddingTop: 50}}>
            <p className='om-header'>Congratulations! You're all set up to receive payments</p>

            <img style={{width: '50%', maxWidth: 700, minWidth: 200, marginTop: 50}} src={success}/>
        </div>

        :
        <>
            <p className='om-header'>Wallet</p>
            <p className='om-subheader'>Set up your wallet to start getting paid for gigs straight to your bank.</p>

            <div style={{width: '100%', minHeight: 400, display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
              
              <img style={{width: '30%', margin: '2rem'}}  src={wallet}/>
              <p className='om-subheader'>No wallet has been set up yet</p>
{console.log(userInfo?.connectData)}
              {(userInfo?.connectData === undefined || userInfo?.connectData === null) && 
                <button className='primary-btn' onClick={createConnectAccount} disabled={signUpInProgress}>
                  {signUpInProgress ? "Setting up..." : "Set up with Stripe"}
                  </button>
              }
              {
              (userInfo?.connectData?.accountId && !userInfo.connectData.paymentsPermitted) 
              && 
                <button className='primary-btn' onClick={createConnectAccount} disabled={signUpInProgress}>
                  {signUpInProgress ? "Setting up..." : "Continue Stripe Setup"}
                </button>
              }

            </div>
          </>
          }
           
        </div>
        </div>

        

  )
}
