import React, { useState, useEffect } from 'react'
import './Auth.css'
import { auth } from '../../Firebase';
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext';
import eyeOpen from '../../Assets/Icons/show.png'
import eyeClosed from '../../Assets/Icons/hide.png'
import tick from '../../Assets/Icons/check.png'
import chevron from '../../Assets/Icons/chevron.png'
import logo from '../../Assets/gofer-gigs.png'
import axios from 'axios'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { useNavigate} from 'react-router-dom'

export default function CreateMusicianAccount() {
    let navigate = useNavigate();

    //Create account state
    let accountType = "Musician"

    //Tab 2
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [number, setNumber] = useState("+44")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [allFieldsComplete, setAllFieldsComplete] = useState(false)
    const [saving, setSaving] = useState(false)


   useEffect(() => {
       if( email && firstName && lastName && number && password && confirmPassword) {
           setAllFieldsComplete(true)
       } else {
           setAllFieldsComplete(false)
       }

   }, [ email, firstName, lastName, number, password, confirmPassword])
        
    const createAccount = () => {
     
        if(!allFieldsComplete) {
            toast.error("Please add all fields")
        } else if(password !== confirmPassword){
            toast.error("Passwords don't match")
        } else {
            setSaving(true)

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/musicians/create-musician-account`, 
            {_id: user.uid, 
              accountType, 
              email, 
              firstName, 
              lastName, 
              number,
              admin:false
            })
            .then(()=>{
              setSaving(false)
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                //Sign out user to allow role to propogate on token
                signOut(auth)
                .then(() =>
                  navigate('/login'))
              })
           
            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setSaving(false)

            })
        })
        .catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage)
          setSaving(false)

          // ..
        });
        }

    }

  return (
    <div id='auth-page'>
        <div id='auth-left-container'>
            <div className='auth-overlay' />
          <div style={{width: '100%', margin: '1rem auto', textAlign:'center', zIndex: 999}}>
            <img style={{width: 100, margin: '1rem auto', filter: 'invert(1'}} src={logo} alt='Om Yoga logo'/>
            <p style={{ fontFamily: 'Arual', fontSize: 28, color: 'white'}}>Gofer Gigs</p>
          </div>
            
          
              <div id='sign-up-steps'>
                <p style={{borderBottom: '1px solid #f1f1f1', padding: '1rem 0', fontFamily: 'Inter', fontSize: 14, color: 'grey'}}>COMPLETE THESE STEPS</p>

                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Choose a profile</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Choose between a Studio, Teacher or Student profile</p>
                      </div>
                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {allFieldsComplete && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Provide personal details</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Kindly provide the answers to the input fields</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Verify Email</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Verify your email address</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Business overview enquiries</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Please provide some answers to the questions </p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
              </div>
            
        </div>

        <div className='auth-right-container'>

          <div className='auth-right-contents'>

            <h1 className='om-header'>Sign up to get gigs</h1>
            <p className='om-subheader'>Please kindly provide your details</p>

       
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>First Name</label>
                        <input type='email' style={{width: 300, maxWidth: '100%'}} className='om-input' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Last Name</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                </div>
               
             

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Email Address</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Mobile Number</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' placeholder='inc +44' value={number} onChange={(e) => setNumber(e.target.value)}/>
                    </div>
                </div>

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Password</label>
                        <div className="wrapper">
                            <input type={showPassword ? 'text' : 'password'} style={{width: 300, flex: 1, maxWidth: '100%'}} className='om-input'  
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPassword ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPassword(!showPassword)}/>
                        </div> 
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Confirm Password</label>
                        <div className="wrapper">
                            <input type={showPasswordConfirm ? 'text' : 'password'} style={{width: 300, flex: 1, maxWidth: '100%'}} className='om-input'  
                            value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPasswordConfirm ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}/>
                        </div> 
                    </div>
                </div>

                <button className='primary-btn' onClick={createAccount} disabled={saving}>{saving ? "Saving..." : "Sign Up"}</button>

               

                
            </div>
            

      
       
            </div>

        </div>

     
    </div>
  )
}
