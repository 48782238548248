import React, { useEffect, useState, useRef} from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';
import axios from 'axios'
import moment from 'moment-timezone'
import './ScheduleView.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import mat from '../../../Assets/Icons/mat.png';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import upload from '../../../Assets/Icons/upload.png'

export default function TimetableEdit() {
    
    const { navOpen } = useOutletContext();
    const {currentUser} = useAuth()
    let {id} = useParams();
    let navigate = useNavigate();
    const [timetable, setTimetable] = useState("")
    const [open, setOpen] = useState(false);
    const [openWalk, setOpenWalk] = useState(false);
    const [otherWalks, setOtherWalks] = useState([])
    const [teacherList, setTeacherList] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [tempTeachers, setTempTeachers] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTeacher, setSelectedTeacher] = useState("");
    const [loading, setLoading] = useState(false)
    const [startStr, setStartStr] = useState("18:00");
    const [endStr, setEndStr] = useState("19:30");
    const [classes, setClasses] = useState([])
    const [price, setPrice] = useState("")
    const [importedClasses, setImportedClasses] = useState([])
    const [unassigned, setUnassigned] = useState([])
    const [studioInfo, setStudioInfo] = useState("")

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseWalk = () => setOpenWalk(false);
 
    //Intitial load
    useEffect(()=>{
        getTimetable()
        getTeachers()
        getClasses()
        getStudio() 
    }, [])

    useEffect(()=>{
        if(timetable){
            setClasses(timetable.classes)
        }
    }, [timetable])

    const getTimetable = () =>{
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-timetable-by-id`,{id})
        .then(function(res){
            if(res.data.published){
                setTimetable(res.data)

            } else {
                setTimetable(res.data)
                setTeachers(res.data.teachers)
                setTempTeachers(res.data.teachers)
                setClasses(res.data.classes)
                //Get list of classes with no teacher assigned
                 let filtered = res.data.classes.filter((item) => !item.teacherId)
                 setUnassigned(filtered)
            }
         
        })
        .catch((e)=>{
          console.log(e)
        })
    }

    const getStudio = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id: currentUser.uid})
        .then((res) => {
          setStudioInfo(res.data)
        })
        .catch((e) => {
          console.log(e)
        })
      }

    const getClasses = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-active-classes`, {studioId: currentUser.uid})
        .then((res) => {
            setImportedClasses(res.data)
         
        })
        .catch((e) => {
          console.log(e)
        })
      }

    const getTeachers = () =>{
        axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
        .then((res)=>{
          setTeacherList(res.data.teachers)
        })
        .catch((e)=>{
          console.log(e)
        })
      }
  
    const addTeachers = (teacher)=>{
     
        if (tempTeachers.some(member => member.id === teacher.teacherId)) {
            return
          } else {
            setTempTeachers(prev=> [...prev, {id: teacher.teacherId, name: `${teacher.name}`, img: teacher.img}])
          }
      }

    const addClassToTeacher = (teacherId, classId) => {

        let teacherInfo = teachers.find(( teacher ) => teacher.id === teacherId)
        // Find the index of the object with classid
        const indexToUpdate = classes.findIndex(obj => obj._id === classId);

        // Check if the object with id  exists
        if (indexToUpdate !== -1) {
            // Update the name and id property of the object
            classes[indexToUpdate].teacherId = teacherInfo.id;
            classes[indexToUpdate].teacherName = teacherInfo.name;

            axios.post(`${process.env.REACT_APP_API_URL}/studios/update-timetable-classes`, {timetableId: id, classes})
            .then(() => {
                getTimetable()
            })
            .catch((e) => {
                console.log(e)
            })

        } else {
        console.log("Object with id not found");
        }
    }
    
    const removeTeacher= (teacher) =>{
        if(window.confirm("Are you sure you want to remove this teacher?")){
            let filteredStaff = timetable.teachers.filter(teach => teach.teacherId !== teacher.id)
            let remainingShifts = timetable.classes.filter((item)=> item.teacherId !== teacher.id)
            let filtered = timetable.classes.filter((item)=> item.teacherId == teacher.id)
            //Delete shifts
            filtered.forEach((element) => {

                axios.post(`${process.env.REACT_APP_API_URL}/remove-class`,{_id: element._id})
                .then(function(res){
                        console.log(res.data)
                })
                .catch((e)=>{
                console.log(e)
                })
            });
            //Remove classes from timetable
                axios.post(`${process.env.REACT_APP_API_URL}/update-walk-and-dogs`,{id, walks:remainingShifts, dogs: filteredStaff} ,{params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
                .then(function(res){
                        console.log(res.data)
                        getTimetable()
                })
                .catch((e)=>{
                console.log(e)
                })
            
        } else {
            return
        }
       
        
    }

      const saveTeachers = () =>{
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/studios/save-teachers`,{id, teachers:tempTeachers})
                .then(function(res){
                    getTimetable()
                    handleClose()
                    setLoading(false)
                })
                .catch((e)=>{
                console.log(e)
                setLoading(false)

                })
      }

      const handleCellClick = (date, teacher) =>{
        let filtered = classes.filter((item, i)=>{ 
            if(item.teacherId == teacher.id && moment(item.start).startOf('day').isSame(moment(date).startOf('day'))){
                item.index=i
                return item
            }
              
        })
           if(filtered.length > 0){
            setOtherWalks(filtered)
            setOpenWalk(true)
            setSelectedDate(date)
            setSelectedTeacher(teacher)
            setStartStr("12:00");setEndStr("13:00")
          } else {
            setOtherWalks([])
            setOpenWalk(true)
            setSelectedDate(date)
            setSelectedTeacher(teacher)
            setStartStr("12:00");setEndStr("13:00")
          }
          
      }


      const addClass = async () => {
       
            setLoading(true)
            let payload = { title: selectedTeacher.name, teacherId: selectedTeacher.id, name: selectedTeacher.name, img: selectedTeacher.img,
                date: new Date(selectedDate).toLocaleDateString('en-GB'), start: moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${startStr}`).tz("Europe/London").format(),end: moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${endStr}`).tz("Europe/London").format(),
                walkScheduleName: timetable.title, walkScheduleId: id, published: timetable.published ? true : false, studioId: currentUser.uid,
                display: 'block', borderColor: '#ffaf26', backgroundColor: 'unset', textColor: '#ffaf26', price
                }
            
            axios.post(`${process.env.REACT_APP_API_URL}/add-class`, {id, payload, classes})
                .then( function(res){
                    getTimetable()
                })
                .catch((e)=>console.log(e))
                .finally(()=>{
                    setLoading(false)
                    handleCloseWalk()
                    setStartStr("12:00"); setEndStr("13:00")
                })
    }

    const removeShift = (selectedShift) => {
       setLoading(true)
       let filtered = classes.filter((item)=> selectedShift._id !== item._id)
        axios.post(`${process.env.REACT_APP_API_URL}/remove`, {id, walkId: selectedShift._id, walks: filtered}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then( function(res){
            setLoading(false)
            getTimetable()
            handleCloseWalk()
            })
        .catch((e)=>{console.log(e);setLoading(false)})
        
}
    const updateShift = (selectedShift, start, end) => {
        let new_array = classes.map((walk) => walk._id == selectedShift._id ? {...walk, start : start, end: end} : walk);

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/change-times`, {id, walks: new_array, walkId: selectedShift._id, start, end}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then( function(res){
            setLoading(false)
            handleCloseWalk()
            getTimetable()
            toast.success("Successfully updated")
            })
        .catch((e)=>{console.log(e);setLoading(false)})
     
}

  
    const returnShift = (teacher, date) =>{
        let filtered = classes.filter((item)=> item.teacherId == teacher.id && moment(item.day).tz('Europe/London').startOf('day').isSame(moment(date).tz('Europe/London').startOf('day')))
        //Render single class
        if(filtered.length === 1){
           
                return <div style={{display:'flex', flexDirection:'column', height:'100%', justifyContent:'center', alignItems: 'center'}} >
                            <p>{filtered[0].name}</p>
                            <p style={{color: 'grey'}}>{filtered[0].start} - {filtered[0].end}</p>
                        </div>

           
        //Check for multiple walks
        } else if(filtered.length > 1){
         
                return <div style={{display:'flex',flexDirection:'column', height:'100%', justifyContent:'center', alignItems: 'center'}} >
                            <img style={{height: 30, width: 30, objectFit:'contain'}} src={mat} alt='Yoga mat icon'/>
                            <p>{filtered.length} Classes</p>
                        </div>


        } else {
            return <div></div>
        }
    }
    

    const publish = () =>{

        if(window.confirm("Are you sure you want to publish timetable?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/studios/publish-timetable`, 
            {
                id, 
                classes, 
                lat: studioInfo.lat, 
                long: studioInfo.long, 
                studioId: currentUser.uid, 
                timetableId: id, 
                venueAddress: `${studioInfo.addressLineOne} ${studioInfo.addressLineTwo} ${studioInfo.town} ${studioInfo.county} ${studioInfo.postcode}`
            })
            .then( function(res){
                getTimetable()
                toast.success(res.data)
                setLoading(false)
                })
            .catch((e)=>{
                console.log(e);
                toast.error("Error publishing")
                setLoading(false)
            })
        } else {
            return
        }
        
    }

    const deleteRota = () =>{

        if(window.confirm("Are you sure you want to delete rota?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/studios/delete-unpublished-timetable`, {_id: id})
            .then( function(res){
                navigate('/timetable')
                toast.success(res.data)
                setLoading(false)
            })
            .catch((e)=>{console.log(e);setLoading(false)})
        } else {
            return
        }
        
    }

    const importClasses = () => {
        if(importedClasses.length < 1){
            toast.error("No classes added")
        } else {
            //Add saved classes to timetable
            let dates = timetable.dates.map((day) => {
              return  { number: moment(day).day(),  day }
            })
            console.log(importedClasses)


            //Create list of teachers
            
           // Reducer function to extract unique user objects without empty teacherId
            const userReducer = (accumulator, currentObject) => {
                // Check if teacher with the same id already exists in the accumulator
                const existingTeacher = accumulator.find(teacher => teacher.id === currentObject.teacherId);
            
                // Check if teacherId is not an empty string
                const isValidTeacherId = currentObject.teacherId.trim() !== '';
            
                // If the teacher doesn't exist and teacherId is valid, add the teacher object to the accumulator
                if (!existingTeacher && isValidTeacherId) {
                accumulator.push({
                    id: currentObject.teacherId,
                    name: currentObject.teacherName,
                    img: currentObject.teacherImg
                });
                }
            
                // Return the updated accumulator for the next iteration
                return accumulator;
            };
            
            // Use Array.reduce() to create a new array of unique user objects without empty teacherId
            const newArrayofTeachers = importedClasses.reduce(userReducer, []);
        
            //Create array of classes
            // Function to replace day number with actual date string
            const replaceDayWithDateString = (object) => {
                const dateObject = dates.find((date) => date.number === object.day);
                if (dateObject) {
                object.day = dateObject.day;
                }
                return object;
            };
            
            // Create a new array with replaced date strings
            const newClassesArray = importedClasses.map(replaceDayWithDateString);
            
            axios.post(`${process.env.REACT_APP_API_URL}/studios/import-from-template`, {timetableId: id, classes: newClassesArray, teachers: newArrayofTeachers})
            .then(() => {
                getTimetable()
            })
            .catch((e) => {
                console.log(e)
            })
            }
    }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'} style={{padding: '2rem 1rem'}}>
        {timetable &&
        <>
        <h1 className='om-header' style={{marginBottom: 20}}>{timetable.title}</h1>
        <p className='om-subheader'>Manage your weekly timetable of classes and teachers</p>

        <button onClick={importClasses} id='import-btn'>
            <img style={{height: 20, width: 20, objectFit:'contain', marginRight: 10}} src={upload}/>
            Import Classes
        </button>
        <table id='rota-table'>
            <thead>
                <tr>
                    <th className='om-subheader' style={{textAlign:'center', fontSize: 16, opacity: 1}}>Teachers</th>
                    {timetable.dates.map((date,i)=>{
                        return <th key={i} style={{textAlign:'center', fontSize: 16, opacity: 1}} className='om-subheader'>{moment(date).format('ddd, Do MMM')}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {teachers.map((teach, i)=>{
                    return ( 
                <tr key={i}>
                    <td>
                        <div className='rota-staff-table-container'>
                            <img className='rota-staff-img' src={teach.img? teach.img : require('../../../Assets/Icons/user-icon.jpeg')}/>
                            <h3 className='om-header' style={{fontSize: 18}}>{teach.name}</h3>
                            <PersonRemoveIcon color='error' onClick={()=>removeTeacher(teach)}/>
                        </div>
                    </td>
                        {timetable.dates.map((date,i)=>{
                            
                        return( 
                            <td  key={i}>
                                <div className='date-cell' onClick={()=>handleCellClick(date, teach)}>{returnShift(teach, date)}</div>
                            </td>
                        )
                    })}

                </tr>
                )})}
               
                 <tr>
                    <td  onClick={handleOpen}><div id='add-employee-btn'><EmojiPeopleIcon sx={{color: '#a28f89', marginRight: 3}}/> <p>+/- Teachers</p></div></td>
                </tr>
                
            </tbody>
        </table>
        {!timetable.published && <>
            <button  id='publish-rota-btn' disabled={loading} onClick={publish}>Publish</button>
            <button id='delete-rota-btn' disabled={loading} onClick={deleteRota}>Delete</button>
        </>
        }
        {
            //List unassigned classes
            unassigned.length > 0 &&
            <table>
                <tbody>
                    {unassigned.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td style={{padding: 10}}>{item.name}</td>
                                <td style={{padding: 10}}>{moment(item.day).format('ddd, Do MMM')}</td>
                                <td style={{padding: 10}}>
                                    <select defaultValue={item.teacherId} onChange={(e) => addClassToTeacher(e.target.value, item._id)}>
                                        <option value="">Unassigned</option>
                                        {teachers.map((teacher, i) => {
                                            return (
                                                <option key={i} value={teacher.id}>{teacher.name}</option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        }
        </>
}
    {/*Teacher modal*/}
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h2>Select teachers</h2>
          {teacherList?.length > 0 
          && 
            teacherList.map((employee, i)=>{
              return(
                   <div key={i} 
                    className={teachers.some(staff => staff.id === employee.teacherId) || tempTeachers.some(staff => staff.id === employee.teacherId)? "staff-name-selected": 'staff-name-container'}
                    onClick={()=>addTeachers(employee)}>
                       <p>{employee.name}</p>
                   </div>)
          })}
            <button disabled={loading} onClick={saveTeachers}>Save</button>
            <button onClick={()=>{handleClose();setTempTeachers(teachers)}}>Cancel</button>
        </Box>
      </Modal>
    {/*Class modal*/}
      <Modal open={openWalk} onClose={handleCloseWalk} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
        {selectedDate && setSelectedTeacher && classes.length> 0 && <>
                <h2>Add another class</h2>

                <h3>{setSelectedTeacher.name}</h3>
                <h3>{moment(selectedDate).format('ddd, DD MMM')}</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Start</th>
                            <th>End</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {classes.map((shift, i)=>{
                        console.log("Other", shift)
                        let start = shift.start;
                        let end = shift.end;
                       

                    return <tr key={i}>
                                <td ><input type='time' defaultValue={moment(shift.start).format('HH:mm')} onChange={(e)=> start= `${moment(shift.start).tz('Europe/London').format('YYYY-MM-DD')}T${e.target.value}`}/></td>
                                <td ><input type='time' defaultValue={moment(shift.end).format('HH:mm')} onChange={(e)=> end= `${moment(shift.end).tz('Europe/London').format('YYYY-MM-DD')}T${e.target.value}`}/></td>
                                <td ><button onClick={()=>updateShift(shift, start, end)}>Save</button></td>
                                <td ><button onClick={()=>removeShift(shift)}>Remove</button></td>
                            </tr> 
                })}
                    </tbody>
                </table>

                <h3>New class</h3>
                
                <div className='row-container'>
                    <label htmlFor='start'>Start Time</label>
                    <input className='shift-date' type='time' id='start'  value={startStr} onChange={(e)=> setStartStr(e.target.value)} />
                </div>
                
                <div className='row-container'>
                    <label  htmlFor='finish'>End Time</label>
                    <input className='shift-date' type='time' id='finish' value={endStr} onChange={(e)=> setEndStr(e.target.value)}/>
                </div>
                <div className='row-container'>
                    <label  htmlFor='price'>Price (£)</label>
                    <input className='shift-date' type='number' id='price' value={price} onChange={(e)=> setPrice(e.target.value)}/>
                </div>

          <button disabled={loading || !startStr || ! endStr || !price} onClick={addClass}>Add class</button>
            </>}
            {selectedDate && setSelectedTeacher && otherWalks.length < 1 && <>
                <h2>Add class</h2>
                <p>Classes will not be active until the timetable is published</p>

                <h3>{setSelectedTeacher.dogName}</h3>
                <h3>{moment(selectedDate).format('ddd, DD MMM')}</h3>

             
                <div className='row-container'>
                    <label htmlFor='start'>Start Time</label>
                    <input className='shift-date' type='time' id='start'  value={startStr} onChange={(e)=> setStartStr(e.target.value)} />
                </div>
                
                <div className='row-container'>
                    <label  htmlFor='finish'>End Time</label>
                    <input className='shift-date' type='time' id='finish' value={endStr} onChange={(e)=> setEndStr(e.target.value)}/>
                </div>

                <div className='row-container'>
                    <label  htmlFor='price'>Price (£)</label>
                    <input className='shift-date' type='number' id='price' value={price} onChange={(e)=> setPrice(e.target.value)}/>
                </div>

          <button disabled={loading || !startStr || !endStr || !price} onClick={addClass}>Save</button>
            </>}
        </Box>
      </Modal>
    </div>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll'
  };