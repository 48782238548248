import React, { useState, useEffect} from 'react'
import './Venues.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import LockedOverlay from './LockedOverlay'
import { useOutletContext } from 'react-router-dom'
import ClassModal from './ClassModal'
import moment from 'moment'
import ClassEditModal from './ClassEditModal'

export default function Venues() {

  let { currentUser, subscription } = useAuth()

  const [tab, setTab] = useState(1)
  const [classes, setClasses] = useState([])
  const [ourTeachers, setOurTeachers] = useState([])
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { navOpen } = useOutletContext();
  const [classInfo, setClassInfo] = useState({})
  //Table state
  const [monday, setMonday] = useState([])
  const [tuesday, setTuesday] = useState([])
  const [wednesday, setWednesday] = useState([])
  const [thursday, setThursday] = useState([])
  const [friday, setFriday] = useState([])
  const [saturday, setSaturday] = useState([])
  const [sunday, setSunday] = useState([])

  useEffect(() => {
    getClasses()
    getStudioTeachers()
  }, [])

  // Helper function to find the maximum length among arrays
const findMaxLength = (...arrays) => {
  return Math.max(...arrays.map(arr => arr.length));
};

const populatedCell = (classDetails) => {
  return(
    <div>
      <p style={{fontSize: 18}}>{classDetails.name}</p>
      <p style={{fontSize: 14, color: '#fafafa'}}>{classDetails.start} - {classDetails.end}</p>
      <p style={{fontSize: 14, color: '#fafafa'}}>{classDetails.teacherName ? `(${classDetails.teacherName})` : "(Unassigned)"}</p>
    </div>
  ) 
}

  const getClasses = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-active-classes`, {studioId: currentUser.uid})
    .then((res) => {
        // Sort the classes by day of the week and start times
        const sortedClasses = res.data.sort((a, b) => {
          const dayComparison = a.day - b.day;

          if (dayComparison !== 0) {
              return dayComparison;
          }

              return moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm'));
        });

          setClasses(sortedClasses);

          // Separate classes by day of the week
          const daysClasses = {
              0: sortedClasses.filter((item) => item.day === 0),
              1: sortedClasses.filter((item) => item.day === 1),
              2: sortedClasses.filter((item) => item.day === 2),
              3: sortedClasses.filter((item) => item.day === 3),
              4: sortedClasses.filter((item) => item.day === 4),
              5: sortedClasses.filter((item) => item.day === 5),
              6: sortedClasses.filter((item) => item.day === 6),
          };

          // Set state for each day
          setMonday(daysClasses[1]);
          setTuesday(daysClasses[2]);
          setWednesday(daysClasses[3]);
          setThursday(daysClasses[4]);
          setFriday(daysClasses[5]);
          setSaturday(daysClasses[6]);
          setSunday(daysClasses[0]);
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const getStudioTeachers = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      !res.data ? setOurTeachers([]) : setOurTeachers(res.data.teachers)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>

      <div id='teacher-dash-content'>

        <p className='om-header'>Venues</p>
        <p className='om-subheader'>Find and apply to join venue rosters near you</p>

        <div className='tab-container' style={{width: 400}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            <p className={tab === 1 ? 'tab-txt-active' : 'tab-txt'}>My Venues</p>
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
          <p className={tab === 2 ? 'tab-txt-active' : 'tab-txt'}>Find Venues</p>
          </div>
        </div>

        <div id='classes-container'>
          {tab ===1 &&
            <p>No Venues added to your acts</p>
          }

          {tab ===2 &&
            <p>No Venues available</p>
          }

       

        </div>    
      </div>
    
    </div>
  )
}
