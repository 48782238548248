import React, { useState, useEffect} from 'react'
import './Musicians.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import LockedOverlay from './LockedOverlay'
import { useOutletContext } from 'react-router-dom'
import InviteMusician from './MusicianInvite'

export default function Musicians() {

  let { currentUser, subscription } = useAuth()
  const [tab, setTab] = useState(1)
  const [allTeachers, setAllTeachers] = useState([])
  const [ourTeachers, setOurTeachers] = useState([])
  const [teacherOpen, setTeacherOpen] = useState(false)
  const { navOpen } = useOutletContext();

  useEffect(() => {
    getTeachers()
    getStudioTeachers()
  }, [])

  const getTeachers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/teachers/get-teachers`)
    .then((res) => {
      console.log(res.data)
      setAllTeachers(res.data)
    })
    .catch((e) => {
      console.log(e)
    })

  }

  const getStudioTeachers = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      !res.data ? setOurTeachers([]) : setOurTeachers(res.data.teachers)

    })
    .catch((e) => {
      console.log(e)
    })

  }


  const addTeacher = (teacher) => {

    let payload = {
      teacherId: teacher._id,
      img: teacher.img,
      name: `${teacher.firstName} ${teacher.lastName}`,
      status: 'Pending'
    }

    axios.post(`${process.env.REACT_APP_API_URL}/studios/add-teacher`, {studioId: currentUser.uid, payload})
    .then((res) => {
      getStudioTeachers()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}
      <div id='teacher-dash-content'>
        <button className='primary-btn' style={{position: 'absolute', top: '1rem', right: '1rem'}} onClick={() => setTeacherOpen(true)}>Invite Musician +</button>
        {teacherOpen && <InviteMusician allTeachers={allTeachers} setTeacherOpen={setTeacherOpen}/>}

        <p className='om-header'>Musicians</p>
        <p className='om-subheader'>Manage and find the perfect musicians for your venue</p>

        <div className='tab-container' style={{width: 560}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            <p className={tab === 1 ? 'tab-txt-active' : 'tab-txt'}>Our Musicians</p>
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            <p className={tab === 2 ? 'tab-txt-active' : 'tab-txt'}>Find Musicians</p>
          </div>
          <div className={tab === 3 ? 'tab-active' : 'tab'} onClick={() => setTab(3)}>
            <p className={tab === 3 ? 'tab-txt-active' : 'tab-txt'}>Applications</p>
          </div>
        </div>

        <div id='teacher-list-container'>

          {tab ===1 &&
          <>
          {ourTeachers?.length < 1 
          ?
          <p>No musicians added</p>
          : 
          ourTeachers?.map((teacher, i) => {
            return (
              <div key={i} className='teacher-snapshot'>
                  <img style={{height: 60, width: 60, borderRadius: 8, backgroudColor: '#f1f1f1', backgroundSize: 'cover', backgroundPosition: 'center', marginRight: 10}}  src={teacher.img? teacher.img : require('../../Assets/Icons/user-icon.jpeg')} alt={teacher.firstName}/>
                  <div>
                    <p>{teacher.name}</p>
                    <p>{teacher.status}</p>
                  </div>
                </div>
            )
          })}
          </>
          }

        {tab === 2 &&
          <>
            {allTeachers.length < 1 
            ?
            <p>No musicians added</p>
            : 
            allTeachers.map((teacher, i) => {
              return (
                <div key={i} className='teacher-snapshot'>
                  <img style={{height: 60, width: 60, borderRadius: 8, backgroudColor: '#f1f1f1', objectFit: 'cover', objectPosition: 'center', marginRight: 10}}  src={teacher.img? teacher.img : require('../../Assets/Icons/user-icon.jpeg')} alt={teacher.firstName}/>
                  <div>
                    <p>{teacher.firstName} {teacher.lastName}</p>
                    {ourTeachers?.some((obj) => obj.teacherId === teacher._id) ?
                      <p style={{color:'green'}}>Added</p>
                      :
                      <button className='primary-btn' style={{height: 28, fontSize: 14, margin: '5px 0', width: 130}} onClick={() => addTeacher(teacher)}>Add to studio</button>

                    }
                  </div>
                </div>
              )
            })}
            </>
          }

        </div>    
      </div>
    
    </div>
  )
}
