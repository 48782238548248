import './App.css';
import Login from './Components/Auth/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './Components/Venue/Dashboard';
import MusicianDashboard from './Components/Musicians/MusicianDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/Auth/RequireAuth'
import VenueUI from './Components/Venue/VenueUI';
import MusicianUI from './Components/Musicians/MusicianUI';
import { AuthProvider } from './Context/AuthContext';
import Timetable from './Components/Venue/Timetables/Timetable';
import Musicians from './Components/Venue/Musicians';
import Financials from './Components/Venue/Financials';
import ArtistFinancials from './Components/Musicians/ArtistFinancials';
import Messages from './Components/Venue/Messages';
import TimetableCreate from './Components/Venue/Timetables/TimetableCreate'
import CreateMusicianAccount from './Components/Auth/CreateMusicianAccount';
import CreateVenueAccount from './Components/Auth/CreateVenueAccount';
import TimetableEdit from './Components/Venue/Timetables/TimetableEdit';
import Settings from './Components/Venue/Settings';
import Gigs from './Components/Venue/Gigs';
import Venues from './Components/Musicians/Venues';
import Acts from './Components/Musicians/Acts';
import ActEdit from './Components/Musicians/ActEdit';
import MessagesMusicians from './Components/Musicians/MessagesMusicians';
import { UserProvider } from './Context/UserContext';

function App() {
  return (
  <AuthProvider>
    <UserProvider>
    <BrowserRouter>
    <Routes>
      
      {/*Public routes */}
      <Route path='/login' element={<Login />} />
      <Route path='/create-musician-account' element={<CreateMusicianAccount />} />
      <Route path='/create-venue-account' element={<CreateVenueAccount />} />


      {/*Venue routes */}
      <Route element={<RequireAuth requiredRole="Venue"  />} >
        <Route path='/venue' element={<VenueUI />} >
          <Route index element={<Dashboard />}/>
          <Route path='timetable' element={<Timetable />}/>
          <Route path='timetable/:id' element={<TimetableEdit />}/>
          <Route path='gigs' element={<Gigs />}/>
          <Route path='musicians' element={<Musicians />}/>
          <Route path='financials' element={<Financials />}/>
          <Route path='messages' element={<Messages />}/>
          <Route path='settings' element={<Settings />}/>
        </Route>
      </Route>

      {/*Musician routes */}
      <Route element={<RequireAuth requiredRole="Musician"  />} >
        <Route path='/musician' element={<MusicianUI />} >
          <Route index element={<MusicianDashboard />}/>
          <Route path='venues' element={<Venues />}/>
          <Route path='acts' element={<Acts />}/>
          <Route path='acts/:id' element={<ActEdit />}/>
          <Route path='financials' element={<ArtistFinancials />}/>
          <Route path='messages' element={<MessagesMusicians />}/>
        </Route>
      </Route>

       {/* Wildcard route for redirecting to login page */}
       <Route path='*' element={<Login/>} />
    
    </Routes>
    
  <ToastContainer position='top-center'/>

  </BrowserRouter>  
  </UserProvider>
  </AuthProvider>    
  );
}

export default App;
