import React, { useState, useEffect} from 'react'
import {toast} from 'react-toastify'
import { useAuth} from '../../Context/AuthContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function ActModal({setActOpen, allTeachers}) {

  const [saving, setSaving] = useState(false)

  let { currentUser} = useAuth()
  let navigate = useNavigate()

  const [actName, setActName] = useState("")
  const [category, setCategory] = useState("")
 
  const handleSubmit = () => {
    if(!actName || !category){
        toast.error("Please add all fields")
    } else {
        setSaving(true)

        let payload = {
          musicianId: currentUser.uid,
          actName,
          category,
          setList: "",
          primaryImg: "",
          images: [],
          videos: [],
          venues: [],
          endorsements:[],
          published: false,
          MGApproved: false
        }

        axios.post(`${process.env.REACT_APP_API_URL}/musicians/create-act`, payload)
        .then((res) => {
          toast.success("Act created")
          setSaving(false)
          console.log(res.data)
          navigate(`${res.data._id}`)
        })
        .catch((e) => {
          console.log(e)
          setSaving(false)
    
        })
    }
   
  }

  return (
    <div id='create-contest'>
        <div id='create-contest-container'>
            <button onClick={() => setActOpen(false)} id='close-contest-btn'>x</button>

            <div className='create-contest-header'>
                <p className='om-header' style={{fontSize: 28}}>Create Act</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add your act info to start the journey to more bookings</p>
            </div>
              <div className='new-contest-container'>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Act Name</p>
                <input className='contest-input' type='text'  value={actName} onChange={(e) => setActName(e.target.value)}/>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Category</p>
                <select className='contest-input' value={category} onChange={(e) => setCategory(e.target.value)}>
                  <option disabled value="">Please select</option>
                  <option value="Solo">Solo</option>
                  <option value="Duo">Duo</option>
                  <option value="Band">Band</option>
                  <option value="DJ">DJ</option>
                </select>
          
                <button style={{marginTop: 50}} className='primary-btn' onClick={handleSubmit} disabled={saving}>{saving ? 'Saving...' : "Save"}</button>
              </div>

        </div>
    </div>
  )
}
