import React, { useState, useEffect} from 'react'
import './Acts.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import LockedOverlay from './LockedOverlay'
import { Link, useOutletContext } from 'react-router-dom'
import ActModal from './ActModal'

export default function Acts() {

  let { currentUser, subscription } = useAuth()
  const [tab, setTab] = useState(1)
  const [acts, setActs] = useState([])
  const [actOpen, setActOpen] = useState(false)
  const { navOpen } = useOutletContext();

  useEffect(() => {
    getActs()
  }, [])

  const getActs = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/musicians/get-acts-by-musician`, {musicianId: currentUser.uid})
    .then((res) => {
      setActs(res.data)
    })
    .catch((e) => {
      console.log(e)
    })

  }


  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}
      <div id='teacher-dash-content'>
        <button className='primary-btn' style={{position: 'absolute', top: '1rem', right: '1rem'}} onClick={() => setActOpen(true)}>Create Act +</button>
        {actOpen && <ActModal  setActOpen={setActOpen}/>}

        <p className='om-header'>Act Manager</p>
        <p className='om-subheader'>Create and edit all your acts ready to be booked for gigs</p>

        <div id='teacher-list-container'>

          {tab ===1 &&
          <>
          {acts.length < 1 
          ?
          <p>No acts added</p>
          : 
          acts.map((act, i) => {
            return (
              <Link to={`${act._id}`} key={i} className='act-snapshot'>
                  <img style={{height: 60, width: 60, borderRadius: 8, backgroudColor: '#f1f1f1', backgroundSize: 'cover', backgroundPosition: 'center', marginRight: 10}}  src={act.primaryImg? act.primaryImg : require('../../Assets/Icons/user-icon.jpeg')} alt={act.actName}/>
                  <div>
                    <p style={{fontSize: 18}}>{act.actName}</p>
                    <p style={{fontSize: 14}}>{act.category}</p>
                  </div>
                </Link>
            )
          })}
          </>
          }

        </div>    
      </div>
    
    </div>
  )
}
