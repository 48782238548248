import React, { useState, useEffect } from 'react'
import './Auth.css'
import { auth } from '../../Firebase';
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext';
import eyeOpen from '../../Assets/Icons/show.png'
import eyeClosed from '../../Assets/Icons/hide.png'
import tick from '../../Assets/Icons/check.png'
import chevron from '../../Assets/Icons/chevron.png'
import logo from '../../Assets/gofer-gigs.png'
import axios from 'axios'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { useNavigate} from 'react-router-dom'

export default function CreateVenueAccount() {
    let navigate = useNavigate();

    //Create account state
    let accountType = "Venue"
    //Tab 1
    const [venueName, setVenueName] = useState("")
    const [addressLineOne, setAddressLineOne] = useState("")
    const [addressLineTwo, setAddressLineTwo] = useState("")
    const [town, setTown] = useState("")
    const [county, setCounty] = useState("")
    const [postcode, setPostcode] = useState("")
    const [lat, setLat] = useState("")
    const [long, setLong] = useState("")

    //Tab 2
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [number, setNumber] = useState("+44")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [allFieldsComplete, setAllFieldsComplete] = useState(false)
    const [step, setStep] = useState(1)
    const [saving, setSaving] = useState(false)

    useEffect( () => {
        if(postcode.length > 5){
            try {
                
                getLatLng()
               
                
            } catch (error) {
                setLat("");
                setLong("");
                console.error("Error fetching data:", error);
            }
        } else {
            setLat("");
            setLong("");
        }
    }, [postcode]);

    const getLatLng = async () => {
       fetch(`https://api.postcodes.io/postcodes/${postcode}`, {
            method: "GET"
        })
        .then(async (res) => {
            let data = await res.json()
           
            setLat(data.result.latitude);
            setLong(data.result.longitude);
        })
        .catch((e) => {
            console.log(e)
        })
        
    }

   useEffect(() => {
       if(venueName && addressLineOne && town && postcode && lat && long && email && firstName && lastName && number && password && confirmPassword) {
           setAllFieldsComplete(true)
       } else {
           setAllFieldsComplete(false)
       }

   }, [venueName, addressLineOne, addressLineTwo, town, postcode, lat, long, email, firstName, lastName, number, password, confirmPassword])
        
    const createAccount = () => {
     
        if(!lat && !long){
            toast.error("Please add valid postcode")
        } else if(!allFieldsComplete) {
            toast.error("Please add all fields")
        } else if(password !== confirmPassword){
            toast.error("Passwords don't match")
        } else {
            setSaving(true)

        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;

            axios.post(`${process.env.REACT_APP_API_URL}/venues/create-venue`, 
            {_id: user.uid, 
              accountType, 
              venueName, 
              addressLineOne, 
              addressLineTwo, 
              town, 
              county,
              postcode, 
              lat, 
              long, 
              email, 
              firstName, 
              lastName, 
              number,
               })
            .then(()=>{
              setSaving(false)
              toast.success("Account created successfully!")
              sendEmailVerification(user)
              .then(() => {
                //Sign out user to allow role to propogate on token
                signOut(auth)
                .then(() =>
                  navigate('/login'))
              })
           
            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setSaving(false)

            })
        })
        .catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage)
          setSaving(false)

          // ..
        });
        }

    }

  return (
    <div id='auth-page'>
        <div id='auth-left-container'>
            <div className='auth-overlay' />
          <div style={{width: '100%', margin: '1rem auto', textAlign:'center', zIndex: 999}}>
            <img style={{width: 100, margin: '1rem auto', filter: 'invert(1'}} src={logo} alt='Om Yoga logo'/>
            <p style={{ fontFamily: 'Arual', fontSize: 28, color: 'white'}}>Gofer Gigs</p>
          </div>
            
          
              <div id='sign-up-steps'>
                <p style={{borderBottom: '1px solid #f1f1f1', padding: '1rem 0', fontFamily: 'Inter', fontSize: 14, color: 'grey'}}>COMPLETE THESE STEPS</p>

                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Choose a profile</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Choose between a Studio, Teacher or Student profile</p>
                      </div>
{console.log(lat, long)}
                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {allFieldsComplete && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Provide personal details</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Kindly provide the answers to the input fields</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Verify Email</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Verify your email address</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Business overview enquiries</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Please provide some answers to the questions </p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
              </div>
            
        </div>

        <div className='auth-right-container'>

          <div className='auth-right-contents'>
          <p className='om-subheader'>{step}/2</p>

            <h1 className='om-header'>Sign up to simplify your live music</h1>
            <p className='om-subheader'>Please kindly provide your details</p>

           {step === 1 &&
            <div style={{display: 'flex', flexWrap:'wrap', flexDirection: 'column', marginTop: 30, maxWidth: '100%', justifyContent: 'center'}}>
                <label>Venue name</label>
                <input type='email' style={{width: 630, maxWidth: '90%'}} className='om-input' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Studio Address line 1</label>
                        <input type='email' style={{width: 300, maxWidth: '100%'}} className='om-input' value={addressLineOne} onChange={(e) => setAddressLineOne(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Address line 2</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={addressLineTwo} onChange={(e) => setAddressLineTwo(e.target.value)}/>
                    </div>
                </div>

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Town/City</label>
                        <input type='email' style={{width: 300, maxWidth: '100%'}} className='om-input' value={town} onChange={(e) => setTown(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>County</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={county} onChange={(e) => setCounty(e.target.value)}/>
                    </div>
                </div>

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Postcode</label>
                        <input type='email' style={{width: 300, maxWidth: '100%'}} className='om-input' value={postcode} onChange={(e) => setPostcode(e.target.value)}/>
                    </div>
                   
                </div>

                <button className='primary-btn' onClick={() => setStep(2)}>Continue</button>
            </div>
            }
            {step === 2 &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>First Name</label>
                        <input type='email' style={{width: 300, maxWidth: '100%'}} className='om-input' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Last Name</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                    </div>
                </div>
               
             

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Email Address</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Mobile Number</label>
                        <input type='text' style={{width: 300, maxWidth: '100%'}} className='om-input' placeholder='inc +44' value={number} onChange={(e) => setNumber(e.target.value)}/>
                    </div>
                </div>

                <div className='om-row' >
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30, marginRight: 30}}>
                        <label>Password</label>
                        <div className="wrapper">
                            <input type={showPassword ? 'text' : 'password'} style={{width: 300, flex: 1, maxWidth: '100%'}} className='om-input'  
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPassword ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPassword(!showPassword)}/>
                        </div> 
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
                        <label>Confirm Password</label>
                        <div className="wrapper">
                            <input type={showPasswordConfirm ? 'text' : 'password'} style={{width: 300, flex: 1, maxWidth: '100%'}} className='om-input'  
                            value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            <img className="auth-input-icon" src={showPasswordConfirm ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}/>
                        </div> 
                    </div>
                </div>

                <div className='om-row' >
                    <button style={{marginRight: 30}} className='primary-btn' onClick={() => setStep(1)}>Back</button>
                    <button className='primary-btn' onClick={createAccount} disabled={saving}>{saving ? "Saving..." : "Sign Up"}</button>
                </div>

               

                
            </div>
            }

      
       
            </div>

        </div>

     
    </div>
  )
}
