export const yogaStyles = [
   // "AcroYoga",
   // "Anusara Yoga",
    "Ashtanga Yoga",
    "Bikram Yoga (Hot Yoga)",
    "Beginner",
    "Hatha Yoga",
    "Iyengar Yoga",
   // "Jivamukti Yoga",
   // "Kripalu Yoga",
    "Kundalini Yoga",
    "Other",
    "Power Yoga",
    "Restorative Yoga",
   // "Sivananda Yoga",
    "Vinyasa Yoga",
    "Yin Yoga",
    "Yoga Nidra"
  ];