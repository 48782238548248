import React, { useState } from 'react'
import './Messages.css'
import search from '../../Assets/Icons/search.png'
import moment from 'moment'
import { useAuth } from '../../Context/AuthContext'
import LockedOverlay from './LockedOverlay'
import { useOutletContext } from 'react-router-dom'

export default function MessagesMusicians() {

  let { subscription} = useAuth()

  const { navOpen } = useOutletContext();


  let myId = 1

  let testData = [
    {senderId: 1, accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 1, accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 1, accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {senderId: 2 ,accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},

  ]

  let communityData = [
    {accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
    {accountType : 'Teacher', name: 'John Smith', createdAt: moment().format(), msg: "Hi, this all sounds great. Can we go ahead and book in please? Hi, this all sounds great. Can we go ahead and book in please?"},
   
  ]

  const [tab, setTab] = useState(1)
  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      {subscription === 'Free' && <LockedOverlay />}

      <div id='message-contents'>

        <div className='om-row' style={{justifyContent: 'space-between'}}>
          <p className='om-header'>Messaging</p>
          <div className='om-row'>
            <div className='om-input' style={{paddingLeft: 'unset', width: 250, marginRight: 10, position: 'relative'}}>
              <img src={search} alt='Search icon' style={{height: 25, position: 'absolute', left: 10, top: 10}}/>
              <input style={{border: 'unset', background: 'unset', height: '100%', width: '100%', paddingLeft: 50, fontSize: 16}}  placeholder='Search messages' />
            </div>
            <button className='primary-btn'>Search</button>
          </div>
        </div>

        <div className='om-row'>
            <div id='messages-left-container'>
              <div className='tab-container' style={{justifyContent: 'left', margin: 0, borderRadius: 0}}>
                <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                  <p>Chats</p>
                  <div className='tab-square'>
                    {testData.length}
                  </div>
                </div>

                <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                  <p>Community Chat</p>
                  <div className='tab-square'>
                    {communityData.length}
                  </div>
                </div>

              </div>

              {tab === 1  ? testData.map((msg, i) => {
                return (
                  <div key={i} className='msg-snapshot'>
                    <div className='msg-avatar'>
                        <p>{msg.name.split(" ")[0].charAt(0)}{msg.name.split(" ")[1].charAt(0)}</p>
                    </div>

                    <div style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14}}>
                        <p style={{textTransform: 'uppercase'}}>{msg.accountType}</p>
                        <p>{moment(msg.createdAt).format('HH:mm A')}</p>
                      </div>

                      <p>{msg.msg}</p>

                    </div>

                  </div>
                )
              })
              :
              communityData.map((msg, i) => {
                return (
                  <div key={i} className='msg-snapshot'>
                    <div className='msg-avatar'>
                        <p>{msg.name.split(" ")[0].charAt(0)}{msg.name.split(" ")[1].charAt(0)}</p>
                    </div>

                    <div style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}>
                      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14}}>
                        <p style={{textTransform: 'uppercase'}}>{msg.accountType}</p>
                        <p>{moment(msg.createdAt).format('HH:mm A')}</p>
                      </div>

                      <p>{msg.msg}</p>

                    </div>

                  </div>
                )
              })
            }

            </div>
            <div id='messages-right-container'>
                {testData.map((msg, i) => {
                  return (
                    <div style={{ width: '100%', minHeight: 100, margin: '1rem 0', display:'flex', alignItems:'center', justifyContent: msg.senderId !== myId ? "left" : "right"  }} key={i}>
                      {msg.senderId !== myId && <div className='msg-avatar-sml'>{msg.name.split(" ")[0].charAt(0)}{msg.name.split(" ")[1].charAt(0)}</div>}

                      <div  className={msg.senderId !== myId ? 'other-sender-msg' : 'my-msg'} >
                        <p>{msg.msg}</p>
                      </div>
                    </div>
                  )
                })}

             

              <div id='msg-box'>
                <div className='msg-avatar-sml'>DF</div>
                <input  placeholder='Type your message' className='om-input' style={{width: '80%'}}/>
              </div>
              
            </div>
        </div>

       

      </div>
    </div>
  )
}
