import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Dashboard.css'
import { useAuth} from '../../Context/AuthContext'
import { useOutletContext } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Map from './Map';
import axios from 'axios'

export default function Dashboard() {

  let { currentUser } = useAuth()

  const [tab, setTab] = useState(1)
  const [events, setEvents] = useState([])

  const { setActive, lat, long, navOpen } = useOutletContext();

  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-events-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      console.log(res.data)
      setEvents(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>

      <div id='business-dash-content'>
          <p style={{fontFamily:'Satoshi', fontSize: 32, letterSpacing: -0.5, color: '#272838'}}>Welcome, <span>{currentUser.displayName}</span></p>
          <p style={{fontFamily:'Inter', fontSize: 18, letterSpacing: -0.5, color: 'grey', margin: '1rem 0'}}>Find your teachers, create classes, events and much more</p>

          <div id='business-dash-tabs' >
            <div className={tab === 1 ? 'tab-active' : 'business-dash-tab'} onClick={() => setTab(1)}>
              <p className={tab === 1 ? 'tab-txt-active' : 'tab-txt'}>Calendar</p>
            </div>
            <div className='business-dash-tab' onClick={() => setTab(2)}>
              <div className={tab === 2 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 2 ? 'tab-txt-active' : 'tab-txt'}>Map</p>
              </div>
            </div>
            
            <div className='business-dash-tab' onClick={() => setTab(3)}>
              <div className={tab === 3 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 3 ? 'tab-txt-active' : 'tab-txt'}>Popular Gigs</p>
                <div className='tab-square'>
                  <p>0</p>
                </div>
              </div>
            </div>

            <div className='business-dash-tab' onClick={() => setTab(4)}>
              <div className={tab === 4 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 4 ? 'tab-txt-active' : 'tab-txt'}>Top Musicians</p>
                <div className='tab-square'>
                  <p>0</p>
                </div>
              </div>
            </div>
           
          </div>

      {tab ===1 && 
            <div id='dash-calendar'>
              <div className='om-row' style={{justifyContent: 'space-between', alignItems: 'center', marginBottom: 10}}>
                  <div>
                      <p className='tab-content-header'>CALENDAR</p>
                      <p className='tab-content-title'>View timetable</p>
                  </div>
                  <Link to='timetable' onClick={() => setActive('Timetable')} >
                      <button className='tab-content-btn' style={{margin: 0}}>Add Event</button>
                  </Link>
              </div>
                
                <FullCalendar 
                  events={events}
                  eventClassNames={'calendar-event'}
                  plugins={[ dayGridPlugin ]}
                  initialView="dayGridMonth"
                  eventDisplay='block'
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: true,
                    meridiem: 'short'
                  }}
                />
            </div>
           }

      {tab === 2 && 
        <Map lat={lat} long={long}/>
        }
      

        {tab === 3 && 
        <div id='popular-jobs-container'>
          <div style={{width: '100%', height: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <p>No Classes Added</p>
          </div>
        </div>
        }

   
            {tab === 4 && 
          <>
            <div id='top-freelance-scroller'>
            <div style={{width: '100%', height: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <p>No Musicians Added</p>
            </div>
        
            
            </div>
            <Link to={'/teachers'}>
              <button  id='more-candidates-btn'>
                See More Musicians
              </button>
            </Link>
          </>
          }

      </div>
      
      <div id='business-dash-sidebar'>
        <div id='notifications-container'>
          <p style={{fontFamily: 'Satoshi', fontSize: 20, letterSpacing: -0.5}}>Notifications</p>
          <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -0.5, color:'grey', marginTop: 5}}>You have no new notifications</p>
          <div id='notifications-scroller'>
             {/* <div className='notification-preview'>
              </div>*/}

             
          </div>

          <Link>View all</Link>
        </div>

        <div id='bus-message-container'>
          <p style={{fontFamily: 'Satoshi', fontSize: 20, letterSpacing: -0.5}}>Messages</p>
          <p style={{fontFamily: 'Inter', fontSize: 14, letterSpacing: -0.5, color:'grey', marginTop: 5}}>You inbox is empty</p>

          <div id='message-scroller'>
              {/*<div className='msg-preview'>

              </div>*/}
             
          </div>

          <Link to={'messages'} onClick={() => setActive('Messages')}>
            <button id='go-to-msgs'>
              Go to messages
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
