import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Current({currentTimetables}) {

  let navigate = useNavigate()

  return (
    <div>
      {currentTimetables.length < 1 ?
      <p>No upcoming timetables</p>
      : 
      <table className='timetable-list'>
        <thead>
          <tr>
            <th>Title</th>
            <th>Classes</th>
            <th>Teachers</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
        {currentTimetables.map((timetable, i) => {
        return (
            <tr key={i} onClick={() => navigate(`${timetable._id}`)}>
                <td>{timetable.title}</td>
                <td>{timetable.classes.length}</td>
                <td>{timetable.teachers.length}</td>
                <td>{timetable.published ? "Published" : "Unpublished"}</td>
            </tr>
         
            )
          })
          }

        </tbody>
      </table>
    
      }
    </div>
  )
}
