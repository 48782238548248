import React, { useState, useEffect} from 'react'
import './Map.css'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext';
import { toast} from 'react-toastify'
import { useOutletContext } from 'react-router-dom';
import gofer from '../../Assets/gofer-gigs.png'

const containerStyle = {
    width: '100%',
    height: '100%'
  };
  
export default function Map({ lat, long }) {

    let { currentUser } = useAuth()
    const { logo } = useOutletContext();

    const center = {
      lat: lat,
      lng: long,
    };
  
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    });
  
    const [map, setMap] = useState(null);
    const [zoom, setZoom] = useState(19);

    useEffect(() => {
        setZoom(19)
    }, [map])

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    
        setMap(map)
      }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null);
    }, []);

    const handleMarkerDrag = (event) => {
        console.log('Marker dragged to:', event.latLng.lat(), event.latLng.lng());
        let newLat = event.latLng.lat();
        let newLong = event.latLng.lng();
        axios.post(`${process.env.REACT_APP_API_URL}/studios/update-location`, {id: currentUser.uid, lat:newLat, long: newLong })
        .then(() => {
            toast.success("Location updated")
        })
        .catch((e) => {
            console.log(e)
        })
    }
  
    return (
      <div id='map-container'>
        {isLoaded && lat && long ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerF 
                key={1} 
                position={center} 
                icon={{
                    url: logo? logo : gofer,
                    scaledSize: new window.google.maps.Size(100, 100),
                }}
                draggable={true}
                onDragEnd={handleMarkerDrag}  
            />
            <></>
          </GoogleMap>
        ) : (
          <></>
        )}
      </div>
  )
}
