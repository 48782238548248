import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Auth.css'
import {  signInWithEmailAndPassword , sendPasswordResetEmail} from "firebase/auth";
import { auth } from '../../Firebase';
import { toast } from 'react-toastify'
import { useAuth } from '../../Context/AuthContext';
import eyeOpen from '../../Assets/Icons/show.png'
import eyeClosed from '../../Assets/Icons/hide.png'
import CreateAccount from './CreateAccount'
import tick from '../../Assets/Icons/check.png'
import chevron from '../../Assets/Icons/chevron.png'
import logo from '../../Assets/gofer-gigs.png'

export default function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tab, setTab] = useState("Log In")
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    //Create account state
    const [accountType, setAccountType] = useState("")
    const [step, setStep] = useState(1)

    let { role, currentUser } = useAuth()
  
    let navigate = useNavigate()

    useEffect(() => {
      if(currentUser && role === "Musician" ){
        navigate('/musician')
      } else if(currentUser && role === "Venue") {
        navigate('/venue')
      } else{
        return
      }
    }, [role, currentUser])

    const login = () =>{
      if(!email || !password){
        toast.error("Please input all fields")
      } else {
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(userCredential, role)
          setLoading(false)

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
          setLoading(false)

        });
      }
      
    }
  
    const resetPassword = () =>{
      if(!email){
        toast.error("Please input email")
      } else {
        sendPasswordResetEmail(auth, email)
        .then(() => {
          toast.success("Password reset email sent!")
         
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(errorMessage)
        });
      }
   
    }
  

  return (
    <div id='auth-page'>
        <div id='auth-left-container'>
          <div className='auth-overlay'/>
          <div style={{width: '100%', margin: '1rem auto', textAlign:'center', zIndex: 999}}>
            <img style={{width: 100, margin: '1rem auto', zIndex: 999, filter: 'invert(1)' }} src={logo} alt='Om Yoga logo'/>
            <p style={{ fontFamily: 'Arual', fontSize: 28, color: 'white'}}>Gofer Gigs</p>
          </div>
            

            {tab === 'Sign Up' &&
              <div id='sign-up-steps'>
                <p style={{borderBottom: '1px solid #f1f1f1', padding: '1rem 0', fontFamily: 'Inter', fontSize: 14, color: 'grey'}}>COMPLETE THESE STEPS</p>

                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Choose a profile</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Choose between a Studio, Teacher or Student profile</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Provide personal details</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Kindly provide the answers to the input fields</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Verify Email</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Verify your email address</p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
                  <div className='sign-up-step'>
                      <div className='check-circle'>
                          {accountType === "Business" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                      </div>

                      <div style={{marginLeft: 10, width:'80%'}}>
                        <p style={{color: '#272838', fontSize: 16, fontFamily: 'Satoshi'}}>Business overview enquiries</p>
                        <p style={{color: '#272838', fontSize: 12, fontFamily: 'Inter'}}>Please provide some answers to the questions </p>
                      </div>

                      <img style={{height: 15}} src={chevron} alt='chevron icon'/>
                  </div>
              </div>
            }
        </div>

        <div className='auth-right-container'>

          <div className='auth-right-contents' >

            <h1 className='om-header'>Welcome to Gofer Gigs</h1>
            <p className='om-subheader'>Please select the one that suits you better</p>
           

            <div className='tab-container' style={{width: 217}}>
              <div className={tab=== 'Sign Up' ? 'tab-active' : 'tab'} onClick={() => setTab("Sign Up")}>
                <p className={tab=== 'Sign Up' ? 'tab-txt-active' : 'tab-txt'}>Sign Up</p>
              </div>
              <div className={tab=== 'Log In' ? 'tab-active' : 'tab'} onClick={() => setTab("Log In")}>
                <p className={tab=== 'Log In' ? 'tab-txt-active' : 'tab-txt'}>Log in</p>
              </div>
            </div>

           {tab === 'Log In' ? 
           <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>
              <label>Email Address</label>
              <input type='email' style={{width: 512, maxWidth: '100%'}} className='om-input' value={email} onChange={(e) => setEmail(e.target.value)}/>

              <label>Password</label>
              <div className="wrapper">
                  <input type={showPassword ? 'text' : 'password'} style={{width: 512, flex: 1,  maxWidth: '100%'}} className='om-input'  
                  value={password} onChange={(e) => setPassword(e.target.value)}/>
                  <img className="auth-input-icon" src={showPassword ? eyeOpen : eyeClosed} alt='Password icon' onClick={() => setShowPassword(!showPassword)}/>
              </div> 

              <p style={{color:'#272838', textAlign:'right'}} onClick={resetPassword}>Forgotten password?</p>
              
              <button style={{width: 120}} className='primary-btn' onClick={login}>{loading? "Logging in..." : "Log In"}</button>

              <p>Don't have an account? <span onClick={() => setTab("Sign Up")}>Sign Up</span></p>
            </div>
            : 

            <CreateAccount 
            setTab={setTab}
            accountType={accountType} setAccountType={setAccountType}
            step={step} setStep={setStep}
            
            />

            }
           
            </div>

        </div>

     
    </div>
  )
}
