import React, { useState, useEffect} from 'react'
import './Gigs.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import LockedOverlay from './LockedOverlay'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
import GigModal from './GigModal'
import GigEditModal from './GigEditModal'

export default function Gigs() {

  let { currentUser, subscription } = useAuth()

  const [tab, setTab] = useState(1)
  const [gigs, setGigs] = useState([])
  const [ourTeachers, setOurTeachers] = useState([])
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { navOpen } = useOutletContext();
  const [classInfo, setClassInfo] = useState({})
  //Table state
  const [monday, setMonday] = useState([])
  const [tuesday, setTuesday] = useState([])
  const [wednesday, setWednesday] = useState([])
  const [thursday, setThursday] = useState([])
  const [friday, setFriday] = useState([])
  const [saturday, setSaturday] = useState([])
  const [sunday, setSunday] = useState([])

  useEffect(() => {
    getGigs()
    getStudioTeachers()
  }, [])

  // Helper function to find the maximum length among arrays
const findMaxLength = (...arrays) => {
  return Math.max(...arrays.map(arr => arr.length));
};

const populatedCell = (gigDetails) => {
  return(
    <div>
      <p style={{fontSize: 18}}>{gigDetails.category}</p>
      <p style={{fontSize: 14, color: '#fafafa'}}>{gigDetails.start} - {gigDetails.end}</p>
    </div>
  ) 
}

  const getGigs = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/venues/get-active-gigs`, {venueId: currentUser.uid})
    .then((res) => {
        // Sort the classes by day of the week and start times
        const sortedGigs = res.data.sort((a, b) => {
          const dayComparison = a.day - b.day;

          if (dayComparison !== 0) {
              return dayComparison;
          }

              return moment(a.start, 'HH:mm').diff(moment(b.start, 'HH:mm'));
        });

          setGigs(sortedGigs);

          // Separate classes by day of the week
          const daysGigs = {
              0: sortedGigs.filter((item) => item.day === 0),
              1: sortedGigs.filter((item) => item.day === 1),
              2: sortedGigs.filter((item) => item.day === 2),
              3: sortedGigs.filter((item) => item.day === 3),
              4: sortedGigs.filter((item) => item.day === 4),
              5: sortedGigs.filter((item) => item.day === 5),
              6: sortedGigs.filter((item) => item.day === 6),
          };
          console.log("days", daysGigs)

          // Set state for each day
          setMonday(daysGigs[1]);
          setTuesday(daysGigs[2]);
          setWednesday(daysGigs[3]);
          setThursday(daysGigs[4]);
          setFriday(daysGigs[5]);
          setSaturday(daysGigs[6]);
          setSunday(daysGigs[0]);
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const getStudioTeachers = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-teachers-by-studio`, {studioId: currentUser.uid})
    .then((res) => {
      !res.data ? setOurTeachers([]) : setOurTeachers(res.data.teachers)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>

      {subscription === 'Free' && <LockedOverlay />}
{console.log("Monday", monday)}
      <div id='teacher-dash-content'>
        <button className='primary-btn' style={{position: 'absolute', top: '1rem', right: '1rem'}} onClick={() => setOpen(true)}>Add Gig +</button>
        {open && <GigModal setOpen={setOpen} getGigs={getGigs}/>}
        {editOpen && <GigEditModal setEditOpen={setEditOpen} teachers={ourTeachers} getGigs={getGigs} classInfo={classInfo}/>}

        <p className='om-header'>Gigs</p>
        <p className='om-subheader'>Manage all of the venues gigs ready to assign musicians</p>

        <div className='tab-container' style={{width: 400}}>
          <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
            <p className={tab === 1 ? 'tab-txt-active' : 'tab-txt'}>Active Gigs</p>
          </div>
          <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
            <p className={tab === 2 ? 'tab-txt-active' : 'tab-txt'}>Inactive Gigs</p>
          </div>
        </div>

        <div id='classes-container'>
          {tab ===1 &&
          <table>
            <thead>
                <tr>
                    <th style={{padding: 10}}>Monday</th>
                    <th style={{padding: 10}}>Tuesday</th>
                    <th style={{padding: 10}}>Wednesday</th>
                    <th style={{padding: 10}}>Thursday</th>
                    <th style={{padding: 10}}>Friday</th>
                    <th style={{padding: 10}}>Saturday</th>
                    <th style={{padding: 10}}>Sunday</th>
                </tr>
            </thead>
            <tbody>
            {[...Array(findMaxLength(monday, tuesday, wednesday, thursday, friday, saturday, sunday))].map((_, index) => (
              <tr key={index}>
                <td className={monday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => monday[index] ? (setEditOpen(true),setClassInfo(monday[index])) : setOpen(true)}>
                  {monday[index] ? populatedCell(monday[index]) : "No Gig"}
                </td>
                <td className={tuesday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => tuesday[index] ? (setEditOpen(true),setClassInfo(tuesday[index])) : setOpen(true)}>
                  {tuesday[index] ? populatedCell(tuesday[index]) : "No Gig"}
                </td>
                <td className={wednesday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => wednesday[index] ? (setEditOpen(true),setClassInfo(wednesday[index])) : setOpen(true)}>
                  {wednesday[index] ? populatedCell(wednesday[index]) : "No Gig"}
                </td>
                <td className={thursday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => thursday[index] ? (setEditOpen(true),setClassInfo(thursday[index])) : setOpen(true)}>
                  {thursday[index] ? populatedCell(thursday[index]) : "No Gig"}
                </td>
                <td className={friday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => friday[index] ? (setEditOpen(true),setClassInfo(friday[index])) : setOpen(true)}>
                  {friday[index] ? populatedCell(friday[index]) : "No Gig"}
                </td>
                <td className={saturday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => saturday[index] ? (setEditOpen(true),setClassInfo(saturday[index])) : setOpen(true)}>
                  {saturday[index] ? populatedCell(saturday[index]) : "No Gig"}
                </td>
                <td className={sunday[index] ? 'cell-with-data' : 'cell-no-data'} 
                  onClick={() => sunday[index] ? (setEditOpen(true),setClassInfo(sunday[index])) : setOpen(true)}>
                  {sunday[index] ? populatedCell(sunday[index]) : "No Gig"}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          }

       

        </div>    
      </div>
    
    </div>
  )
}
