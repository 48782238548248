import React, { useState, useEffect} from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
import deleteIcon from '../../Assets/delete.png'
import YouTube from 'react-youtube';
//firebase
import { storage, auth } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


export default function ActEdit() {

  const { navOpen } = useOutletContext();
  let { currentUser } = useAuth()
  let {id} = useParams()

  //Youtube options
  const opts = {
    width: '175',
    height: '100',
    playerVars: {
      controls: 1,
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
      fs: 1,
    }
  }
  
  const [primaryImg, setPrimaryImg] = useState("")
  const [actName, setActName] = useState("")
  const [category, setCategory] = useState("")
  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [setList, setSetList] = useState("")
  const [saving, setSaving] = useState(false)
  const [savingVideo, setSavingVideo] = useState(false)
  const [openVideo, setOpenVideo] = useState(false)
  const [link, setLink] = useState("")

  useEffect(() => {
    getAct()
  }, [])

  const getAct = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/musicians/get-act-info`, { actId: id })
    .then((res) => {
        console.log(res.data)
        setActName(res.data.actName)
        setPrimaryImg(res.data.primaryImg)
        setCategory(res.data.category)
        setImages(res.data.images)
        setVideos(res.data.videos)
        setSetList(res.data.setList)
    })
    .catch((e) => {
        console.log(e)
    })
  }

  //firebase storage

  const ImgRef = ref(storage, `acts/${id}/${uuidv4()}`);
  const addImg = (file) =>{
    if(file?.size > 2097152){
      toast.error("Image must be less than 2mb")
    } else if( !file){
      return
    } else {
      uploadBytes(ImgRef, file).then(() => {
          getDownloadURL(ImgRef)
          .then((url) => {
              setImages([...images, url])
              saveImages(url)     
          })
          .catch((error) => {
              console.log(error)
          });
        });
    }
  }

  const saveImages = (url) =>{
    axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-act-images`, {actId: id, images: [...images, url]})
    .then(() => {
        console.log("Updated")
        getAct()
    })
    .catch((e) => {
        console.log(e)
    })
  }

  const savePrimaryImg = (url) =>{
    axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-primary-img`, {actId: id, primaryImg: url})
    .then(() => {
        console.log("Updated")
        getAct()
    })
    .catch((e) => {
        console.log(e)
    })
  }

  const handleSave = () => {
    if(!actName || !category ){
      toast.error("Please add name and category")
    } else {
      setSaving(true)
      axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-act`, {actId: id, actName, category, setList})
      .then(() => {
          getAct()
          setSaving(false)
          toast.success("Updated")
      })
      .catch((e) => {
          console.log(e)
          setSaving(false)
      })
    }
   
  }

  const deleteImg = ( index) => {
    if(images[index] === primaryImg){
      toast.error("Cannot delete your primary image")
    } else {
      if(window.confirm("Are you sure you want to delete this image?")){
        
        let filtered = images.filter((_, i) => i !== index)
        axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-act-images`, {actId: id, images: filtered})
        .then(() => {
            toast.success("Deleted")
            getAct()
        })
        .catch((e) => {
            toast.error("Error deleting")
            console.log(e)
        })
      } else {
        return
      }
    }
  }

  const handleVideo = () => {
    if(!link){
      return
    } else {
      setSavingVideo(true)
      let newArray = [...videos, link]

      if(ExtractEmbedFromVideoUrl(link) === ""){
        toast.error("Please enter a valid youtube url")
        setSavingVideo(false)

      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-act-videos`, {actId: id, videos: newArray})
        .then(() => {
          toast.success("Video added")
          setLink("")
          setOpenVideo(false)
          setSavingVideo(false)
          getAct()
        })
        .catch((e) => {
            toast.error("Error adding video")
            setSavingVideo(false)
            console.log(e)
        })

      }
    }
  }

  const deleteVideo = (index) => {
   
      if(window.confirm("Are you sure you want to delete this video?")){
        
        let filtered = videos.filter((_, i) => i !== index)
        axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-act-videos`, {actId: id, videos: filtered})
        .then(() => {
            toast.success("Updated")
            getAct()
        })
        .catch((e) => {
            toast.error("Error deleting")
            console.log(e)
        })
      } else {
        return
      }
  }

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
        <div id='teacher-dash-content'>
          <p className='om-header'>Edit Act</p>
          <p className='om-subheader'>Manage and edit your act to include setlists and media to best showcase your talents to venues</p>

          <div className='profile-container'>
            <label>Act name</label>
            <input className='om-input' style={{width: 500, maxWidth: '60vw'}} value={actName} onChange={(e) => setActName(e.target.value)}/>
          </div>

          <div className='profile-container'>
            <label>Category</label>
            <select className='om-input' style={{width: 500, maxWidth: '60vw'}} value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="" disabled>Select</option>
              <option value="Solo">Solo</option>
              <option value="Duo">Duo</option>
              <option value="Band">Band</option>
              <option value="DJ">DJ</option>
            </select>
          </div>

          <div className='media-container'>
            <div style={{display:'flex', flexDirection:'column'}}>
              <label>Images</label>
              <p style={{color:'grey', fontSize: 14}}>(Select Primary)</p>
            </div>
            <div className='upload-media'>
                <p style={{fontSize: 32}}>+</p>
                  <input type="file" accept="image/*" id='file-input'  onChange={(e)=>addImg(e.target.files[0])}/>
            </div>

            {images.map((image, i) => {
              return (
              <div 
                key={i} 
                className={primaryImg === image ? 'act-img-highlighted' : 'act-img-preview'} 
                style={{backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                onClick={() => savePrimaryImg(image)}
              >
                <img className='delete-img' src={deleteIcon} alt='Delete Image Icon' onClick={(e) =>{ e.stopPropagation(); deleteImg(i)}}/>
              </div>
              )
            })}
            
          </div>

          <div className='media-container'>
            <div style={{display:'flex', flexDirection:'column'}}>
              <label>Videos</label>
            </div>
            <div className='upload-media' onClick={() => setOpenVideo(true)}>
                <p style={{fontSize: 32}}>+</p>
            </div>

            {videos.map((video, i) => {
              return (
              <div 
                key={i} 
                className='act-video' 
              >
                 <YouTube
                  videoId={video && ExtractEmbedFromVideoUrl(video)}
                  opts={opts}
                  onPlay={(e) => e.target.setPlaybackQuality('small')}
                />
                <img className='delete-img' src={deleteIcon} alt='Delete Image Icon' onClick={(e) =>{ e.stopPropagation(); deleteVideo(i)}}/>

              </div>
              )
            })}
            
          </div>

          <div className='profile-container'>
            <label>Setlist</label>
            <textarea id='setlist' placeholder='Write an example set list that best represents your style' value={setList} onChange={(e) => setSetList(e.target.value)}></textarea>

          </div>

          <div className='profile-container'>
            <button style={{width: 160}} className='primary-btn' onClick={handleSave} disabled={saving}>{saving ? "Saving..." : "Save"}</button>

          </div>
        </div>

        {openVideo && 
           <div id='create-contest'>
           <div id='create-contest-container'>
               <button onClick={() => setOpenVideo(false)} id='close-contest-btn'>x</button>
   
               <div className='create-contest-header'>
                   <p className='om-header' style={{fontSize: 28}}>Add Video</p>
   
                   <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add a video link to add to your profile</p>
               </div>
                 <div className='new-contest-container'>
   
                   <p className='om-header' style={{fontSize: 20, marginBottom: 10}}>Youtube Link</p>
                   <input className='contest-input' type='text' placeholder='eg. https://youtube.com/my-amazing-vid'  value={link} onChange={(e) => setLink(e.target.value)}/>
                

                   <button style={{marginTop: 20}} className='primary-btn' onClick={handleVideo} disabled={savingVideo}>{savingVideo? "Adding..." :"Add video"}</button>
                 </div>
   
           </div>
       </div>
        }
    </div>
  )
}


export const ExtractEmbedFromVideoUrl = (videoLink) => {
  /*
  **  cover the expected formats:
  **    https://youtu.be/VIDEO_ID
  **    https://www.youtube.com/embed/VIDEO_ID
  **    https://www.youtube.com/v/VIDEO_ID
  **    https://www.youtube.com/watch?v=VIDEO_ID
  **    https://www.youtube.com/watch?feature=player_embedded&v=VIDEO_ID
  **    https://www.youtube.com/shorts/VIDEO_ID
  **  where VIDEO_ID is an alphanumeric of length 11
  **  and enforce https presence, http is not expected
  */

  const youtubeRegex = /(?:https?:\/\/(?:youtu\.be\/|www\.youtube\.com\/embed\/|www\.youtube\.com\/v\/|www\.youtube\.com\/watch\?v=|www\.youtube\.com\/watch\?feature=player_embedded&v=|www\.youtube\.com\/shorts\/))(?<videoId>[A-Za-z0-9_-]{11})\/?/;

  const match = videoLink.match(youtubeRegex);

  if (match && match.groups && match.groups.videoId) {
    return match.groups.videoId;
  } else {
    return "";
  }
}
