import React from 'react'

export default function Past({pastTimetables}) {
  return (
    <div>
      {pastTimetables.length < 1 ?
      <p>No past timetables</p>
      : 
      pastTimetables.map((timetable, i) => {
        return (
          <div key={i}>
            {timetable.title}
          </div>
        )
      })
      }
    </div>
  )
}
