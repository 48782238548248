import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './VenueUI.css'
import {  signOut, sendEmailVerification } from "firebase/auth";
import { auth } from '../../Firebase';
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import goferLogo from '../../Assets/gofer-gigs.png'
import axios from 'axios'

export default function StudioUI() {
  let { currentUser} = useAuth()
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(window.screen.width > 750 ? true : false)

  const [active, setActive] = useState('Dashboard')
  const [lat, setLat] = useState("")
  const [long, setLong] = useState("")
  const [logo, setLogo] = useState("")
  const [verified, setVerified] = useState(currentUser.emailVerified)

  useEffect(() => {
    getStudio()
  }, [])

  const getStudio = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/studios/get-studio-by-id`, {id: currentUser.uid})
    .then((res) => {
      setLat(res.data.lat)
      setLong(res.data.long)
      setLogo(res.data.logo)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    let url = location.pathname

    if(url === '/venue'){
      setActive("Dashboard")
    } else if(url.startsWith('/venue/timetable')){
      setActive("Timetable")
    } else if(url.startsWith('/venue/gigs')){
      setActive("Gigs")
    } else if(url.startsWith('/venue/musicians')){
      setActive("Musicians")
    } else if(url.startsWith('/venue/financials')){
      setActive("Financials")
    } else if(url.startsWith('/venue/messages')){
      setActive("Messages")
    } else {
      setActive("")
    }

  }, [location])

    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }

      const sendVerification = () => {
        sendEmailVerification(auth.currentUser)
        .then(() => {
          toast.success("Email verification sent!")
        });
      }

  return (
    <div>
        <header className={navOpen ?'business-nav' : 'business-nav-closed'}>
        <button onClick={() => setNavOpen(!navOpen)} id='toggle-nav-btn'>{navOpen ? `<` : `>`}</button>

          <div style={!navOpen ? {overflow:'hidden', transition: '0.5s'}: null}>

            <img src={goferLogo} style={{width: 70, marginLeft: '30%', marginBottom: 30}}/>

            <nav>
              <p style={{fontFamily: 'Inter', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/venue'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Dashboard' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>
                <Link to={'timetable'} className={active === 'Timetable' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Timetable' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/jobs.png')} alt='Timetable icon'/>
                  <p className={active === 'Timetable' ? 'business-link-txt-active' : 'business-link-txt'}>Timetable</p>
                </Link>
                <Link to={'gigs'} className={active === 'Gigs' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Gigs' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/mic.png')} alt='Gigs icon'/>
                  <p className={active === 'Gigs' ? 'business-link-txt-active' : 'business-link-txt'}>Gigs</p>
                </Link>
                <Link to={'musicians'} className={active === 'Musicians' ? 'business-nav-item-active' : 'business-nav-item'}onClick={() => window.screen.width < 750 ? setNavOpen(false) : null} >
                  <img className={active === 'Musicians' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/applicants.png')} alt='Teachers icon'/>
                  <p className={active === 'Musicians' ? 'business-link-txt-active' : 'business-link-txt'}>Musicians</p>
                </Link>
                <Link to={'financials'} className={active === 'Financials' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Financials' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/wallet.png')} alt='Wallet icon'/>
                  <p className={active === 'Financials' ? 'business-link-txt-active' : 'business-link-txt'}>Financials</p>
                </Link>
                <Link  to={'messages'} className={active === 'Messages' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={active === 'Messages' ? 'nav-icon icon-filter' : 'nav-icon'} src={require('../../Assets/Icons/messages.png')} alt='Messages icon'/>
                  <p className={active === 'Messages' ? 'business-link-txt-active' : 'business-link-txt'}>Messages</p>
                </Link>

                <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon' src={require('../../Assets/Icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

            </nav>
            </div>

            <Link to={'settings'} className='om-row' style={!navOpen ? {display: 'none'} : null} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
              {logo ?
              <img id='business-avatar' src={logo} alt={currentUser?.displayName}/>
              :
              <div id='business-avatar' style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                <p style={{fontSize: 12, textAlign:'center', color: 'black'}}>Upload Logo</p>
              </div>

              }
              <p style={{marginLeft: 15, fontFamily: 'Inter', fontWeight: 600, color: 'black'}}>{currentUser?.displayName}</p>
            </Link>

        </header>

        {/*
        
        !verified && 
       <div id='blur-overlay'>

          <div className='blur-info-container'>

            <div style={{width: '100%', height: 300, backgroundColor: '#f1f1f1', borderRadius: 12, marginBottom: 20}}>

            </div>

            <h2 className='om-header' style={{fontSize: 32}}>We just sent you a verification email</h2>

            <p className='om-subheader' style={{fontSize: 18}}>Hi there, we just sent you a secure verification link. Kindly check your inbox and spam. </p>

            <button className='primary-btn' style={{fontSize: 18, width: 150}} onClick={sendVerification}>Resend link</button>

          </div>

        </div>*/
}

        <Outlet context={{active, setActive, lat, long, logo, navOpen, setNavOpen}}/>
    </div>
  )
}
