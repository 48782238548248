import React, { useState, useEffect } from 'react'
import { yogaStyles} from '../../Utilities/yogaStyles'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function GigModal({setOpen, getGigs}) {

    let { currentUser } = useAuth()

    const [category, setCategory] = useState("")
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [day, setDay] = useState("")
    const [budget, setBudget] = useState("")

    const [saving, setSaving] = useState(false)

    const handleSubmit = () => {
        let payload = {
            category,
            day,
            venueId: currentUser.uid,
            venueName: currentUser.displayName,
            start,
            end,
            budget,
            active: true
        }
        if( !category || !day || !start || !end ){
            toast.error("Please add all fields")        
        } else {
            setSaving(true)
            axios.post(`${process.env.REACT_APP_API_URL}/venues/create-gig-by-venue`, payload)
            .then(() => {
                setOpen(false)
                getGigs()
                toast.success("Successfully added")
                setSaving(false)

            })
            .catch((e) => {
                console.log(e)
                toast.error("Error creating")
                setSaving(false)

            })
        }
    }

    const handleDay = (day) => {
        setDay(day)

    }

  return (
    <div id='create-contest'>
        <div id='create-contest-container'>
            <button onClick={() => setOpen(false)} id='close-contest-btn'>x</button>

            <div className='create-contest-header'>
                <p className='om-header' style={{fontSize: 28}}>Add New Gig</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add a new regular gig to automatically populate on your timetables</p>
            </div>
              <div className='new-contest-container'>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Category</p>
                <select className='contest-input'  value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Please select</option>
                    <option value="Solo">Solo</option>
                    <option value="Duo">Duo</option>
                    <option value="Band">Band</option>
                </select>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Day of week</p>
                <div style={{display:'flex', justifyContent:'space-between', width: '80%'}}>
                    <div>
                        <p>M</p>
                        <input type='radio' name='days' value={1} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>T</p>
                        <input type='radio' name='days' value={2} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>W</p>
                        <input type='radio' name='days' value={3} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>T</p>
                        <input type='radio' name='days' value={4} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>F</p>
                        <input type='radio' name='days' value={5} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>S</p>
                        <input type='radio' name='days' value={6} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>S</p>
                        <input type='radio' name='days' value={0} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Start time</p>
                        <input className='contest-input'  type='time' value={start} onChange={(e) => setStart(e.target.value)}/>
                    </div>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>End time</p>
                        <input className='contest-input'  type='time' value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '100%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Budget</p>
                        <p style={{color: 'grey', fontSize: 14, marginBottom: 10}}>Please be realistic to ensure you get the best quality musicians</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px' }}>£</span>
                            <input className='contest-input' type='number' value={budget} onChange={(e) => setBudget(e.target.value)} />
                        </div>
                    </div>
                </div>
          
                <button style={{marginTop: 20}} className='primary-btn' onClick={handleSubmit} disabled={saving}>{saving? "Creating" :"Create class"}</button>
              </div>

        </div>
    </div>
  )
}
