import React, { useState, useEffect } from 'react'
import { yogaStyles} from '../../Utilities/yogaStyles'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'

export default function GigEditModal({setEditOpen, teachers, getClasses, classInfo}) {

    let { currentUser } = useAuth()

    const [name, setName] = useState("")
    const [style, setStyle] = useState("")
    const [teacherId, setTeacherId] = useState("")
    const [teacherName, setTeacherName] = useState("")
    const [teacherImg, setTeacherImg] = useState("")
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [day, setDay] = useState("")
    const [spaces, setSpaces] = useState("")
    const [price, setPrice] = useState("")

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(classInfo){
            setName(classInfo.name)
            setStyle(classInfo.style)
            setTeacherId(classInfo.teacherId)
            setTeacherName(classInfo.teacherName)
            setTeacherImg(classInfo.teacherImg)
            setStart(classInfo.start)
            setEnd(classInfo.end)
            setDay(classInfo.day)
            setSpaces(classInfo.spaces)
            setPrice(classInfo.price)
        }
       

    },[classInfo])

    useEffect(() => {
        if(teacherId){
            let selected = teachers.filter((teacher) => teacher.teacherId === teacherId)
            setTeacherName(selected[0].name)
            setTeacherImg(selected[0].img)
        } else {
            return
        }

    }, [teacherId])

    const handleSubmit = () => {
        let payload = {
            name,
            style,
            teacherName,
            teacherId,
            teacherImg,
            day,
            studioId: currentUser.uid,
            studioName: currentUser.displayName,
            start,
            end,
            spaces,
            price,
            active: true
        }
        console.log(payload)
        if(!name || !style || !day || !start || !end || !spaces){
            toast.error("Please add all fields")        
        } else {
            setSaving(true)
            axios.post(`${process.env.REACT_APP_API_URL}/studios/update-class`, {classId: classInfo._id, payload})
            .then((res) => {
                setEditOpen(false)
                getClasses()
                toast.success(res.data)
                setSaving(false) 

            })
            .catch((e) => {
                console.log(e)
                toast.error("Error updating")
                setSaving(false)

            })
        }
    }

    const handleDay = (day) => {
        setDay(day)

    }

  return (
    <div id='create-contest'>
        <div id='create-contest-container'>
            <button onClick={() => setEditOpen(false)} id='close-contest-btn'>x</button>

            <div className='create-contest-header'>
                <p className='om-header' style={{fontSize: 28}}>Edit Class</p>

                <p className='om-subheader' style={{fontSize: 16, margin: '10px 0'}}>Add a new regular class to automatically populate on your timetables</p>
            </div>
              <div className='new-contest-container'>

                <p className='om-header' style={{fontSize: 20, marginBottom: 10}}>Class Name</p>
                <input className='contest-input' type='text' placeholder='eg. Hatha'  value={name} onChange={(e) => setName(e.target.value)}/>
                
                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Yoga Style (used for filtering only)</p>
                <select className='contest-input'  value={style} onChange={(e) => setStyle(e.target.value)}>
                    <option value="">Please select</option>
                    {yogaStyles.map((style, i) => {
                        return <option key={i} value={style}>{style}</option>
                    })}

                </select>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Assign Teacher</p>
                <select className='contest-input'  value={teacherId} onChange={(e) => setTeacherId(e.target.value)}>
                    <option value="">Unallocated</option>
                    {teachers.map((teacher, i) => {
                        return (
                            <option key={i} value={teacher.teacherId}>{teacher.name}</option>
                        )
                    })}

                </select>

                <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Day of week</p>
                <div style={{display:'flex', justifyContent:'space-between', width: '80%'}}>
                    <div>
                        <p>M</p>
                        <input type='radio' name='days' checked={day === 1} value={1} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>T</p>
                        <input type='radio' name='days' checked={day === 2} value={2} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>W</p>
                        <input type='radio' name='days' checked={day === 3} value={3} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>T</p>
                        <input type='radio' name='days' checked={day === 4} value={4} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>F</p>
                        <input type='radio' name='days' checked={day === 5} value={5} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>S</p>
                        <input type='radio' name='days' checked={day === 6} value={6} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                    <div>
                        <p>S</p>
                        <input type='radio' name='days' checked={day === 0} value={0} onChange={(e) => handleDay(e.target.value)}/>
                    </div>
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Start time</p>
                        <input className='contest-input'  type='time' value={start} onChange={(e) => setStart(e.target.value)}/>
                    </div>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>End time</p>
                        <input className='contest-input'  type='time' value={end} onChange={(e) => setEnd(e.target.value)}/>
                    </div>

                   
                </div>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Class price</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px' }}>£</span>
                            <input className='contest-input' type='number' value={price} onChange={(e) => setPrice(e.target.value)} />
                        </div>
                    </div>
                    <div style={{width: '45%'}}>
                        <p className='om-header' style={{fontSize: 20, margin: '30px 0 10px 0'}}>Spaces available</p>
                        <input className='contest-input'  type='number' value={spaces} onChange={(e) => setSpaces(e.target.value)}/>
                    </div>

                   
                </div>
                


          
                <button style={{marginTop: 20}} className='primary-btn' onClick={handleSubmit} disabled={saving}>{saving? "Updating..." :"Update class"}</button>
              </div>

        </div>
    </div>
  )
}
