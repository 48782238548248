import React from 'react'
import wallet from '../../Assets/empty-wallet.png'
import { useOutletContext } from 'react-router-dom';

export default function Financials() {

  const { navOpen } = useOutletContext();

  return (
    <div className={navOpen ? 'business-outlet' : 'business-outlet-expanded'}>
      <div id='business-dash-content' style={{width: '100%'}}>
            <p className='om-header'>Financials</p>
            <p className='om-subheader'>Manage all you money in and out</p>

            <div className='gradient-container'>
              <p style={{fontFamily:'Satoshi', fontSize: 24, letterSpacing: -0.5, color: '#fafafa'}}>Set up your wallet to start managing studio payments and subscriptions</p>
              <p style={{fontFamily:'Inter', fontSize: 16, letterSpacing: -0.5, color: '#dfdfdf', margin: '1rem 0', maxWidth: 600, lineHeight: 1.6}}>Check pending payments, payment history and more</p>
              
          </div>

          <div style={{width: '100%', minHeight: 400, display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
            
            <img style={{width: '30%', margin: '2rem'}}  src={wallet}/>
            <p className='om-subheader'>No wallet has been set up yet</p>
          </div>
        </div>

        

    </div>
  )
}
