import React, { useState} from 'react'
import './Auth.css'
import tick from '../../Assets/Icons/check.png'
import { Link } from 'react-router-dom'

export default function CreateAccount({setTab, accountType, setAccountType, setStep, step}) {

  return (

    <div style={{display: 'flex', flexDirection: 'column', marginTop: 30}}>

            {step === 1 &&
            <>

            <div className={accountType === "Venue" ? 'profile-choice-active' : 'profile-choice'} onClick={() => setAccountType("Venue")}>
                <div className='studio-avatar'>

                </div>

                <div>
                    <p className='om-header' style={{fontSize: 26, margin: 0}}>Sign up as a Venue</p>
                    <p className='om-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>Effortlessly build a timetable of entertainment for your venue and build a roster of musicians you can trust to deliver on the atmosphere your venue deserves.</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Venue" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>  

            <div className={accountType === "Musician" ? 'profile-choice-active' : 'profile-choice'}  onClick={() => setAccountType("Musician")}>
                <div className='teacher-avatar'>

                </div>
                <div>
                    <p className='om-header' style={{fontSize: 26, margin: 0}}>Sign up as a Musician</p>
                    <p className='om-subheader' style={{fontSize: 16, margin: 0, marginTop: 10}}>Build out your musician profile and find high quality gigs in your areas. Allowing you to spend less time on finding work and more time on being a great musician.</p>

                </div>

                <div className='check-circle'>
                    {accountType === "Musician" && <img style={{height: 20}} src={tick} alt='tick icon'/>}
                </div>
            </div>   

            <p style={{fontSize: 16}} className='om-subheader'>Already have an account? <span onClick={() => setTab("Log In")}>Log In</span></p>           
        
            {accountType && 
            <Link to={accountType === "Venue" ? '/create-venue-account' : '/create-musician-account'}>
                <button style={{width: 100, marginTop: 30}} className='primary-btn'>
                    Continue
                </button>
            </Link>}
            </>
            }

            
 
    </div>
  )
}
