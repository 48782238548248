import React from 'react'
import './Locked.css'
import lock from '../../Assets/lock.png'

export default function LockedOverlay() {
  return (
    <div id='locked'>
      <img style={{width: 175, height: 175, opacity: 0.5}} src={lock}/>
    </div>
  )
}
